import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './modules/site/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./pages/brochure/brochure.module').then((m) => m.BrochureModule),
  },
  { path: 'book', component: LayoutComponent, loadChildren: () => import('./pages/book/book.module').then((m) => m.BookModule) },
  { path: 'podcast', component: LayoutComponent, loadChildren: () => import('./pages/podcast/podcast.module').then((m) => m.PodcastModule) },
  { path: 'blog', component: LayoutComponent, loadChildren: () => import('./pages/blog/blog.module').then((m) => m.BlogModule) },
  { path: 'about', component: LayoutComponent, loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule) },
  { path: 'resume', component: LayoutComponent, loadChildren: () => import('./pages/resume/resume.module').then((m) => m.ResumeModule) },
  { path: 'events', component: LayoutComponent, loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsModule) },
  { path: 'privacy', component: LayoutComponent, loadChildren: () => import('./pages/privacy/privacy.module').then((m) => m.PrivacyModule) },
  { path: 'sudoku', component: LayoutComponent, loadChildren: () => import('./games/sudoku/sudoku.module').then((m) => m.SudokuModule) },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
})
export class AppRoutingModule {}

import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationToolbarComponent } from './navigation-toolbar/navigation-toolbar.component';

const COMPONENTS = [FooterComponent, HeaderComponent, LayoutComponent, NavigationComponent, NavigationToolbarComponent];
const MODULES = [SharedModule];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  exports: [...COMPONENTS],
})
export class SiteModule {}

<footer>
  <section id="aa-footer">

    <svg class="svgcolor-light" preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%" version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0 L50 100 L100 0 Z"></path>
    </svg>

    <div class="aa-footer--container">

      <div class="aa-footer--container-item">
        <h2>angular architecture</h2>
        <p>Website and resources of AngularArchitecture.com are the property of BUILD MOTION, LLC.</p>
        <p class="copyright-text">Copyright &copy; {{copyright}} BUILD MOTION, LLC <br>
          Designed by <a rel="nofollow" href="http://www.buildmotion.com" target="_blank">BUILD MOTION, LLC</a></p>
      </div>

      <!-- <div class="col-md-1 col-sm-1"></div> -->

      <div class="aa-footer--container-item">
        <h2>Our Studio</h2>
        <p>
          BUILD MOTION STUDIO <br>
          Denver, Colorado, USA
        </p>
        <ul class="social-icon">
          <li><a href="{{social.linkedin.URL}}" title="{{social.linkedin.name}}" target="_blank" class="fa fa-linkedin wow bounceIn" data-wow-delay="1.6s"></a></li>
          <li><a href="{{social.twitter.URL}}" title="{{social.twitter.name}}" target="_blank" class="fa fa-twitter wow bounceIn" data-wow-delay="1.6s"></a></li>
          <li><a href="{{social.instagram.URL}}" title="{{social.instagram.name}}" target="_blank" class="fa fa-instagram wow bounceIn" data-wow-delay="1.6s"></a></li>
          <li><a href="{{social.github.URL}}" title="{{social.github.name}}" target="_blank" class="fa fa-github wow bounceIn" data-wow-delay="1.6s"></a></li>
        </ul>
      </div>

      <div class="aa-footer--container-item">
        <h2>resources</h2>
        <ul class="footer-link">
          <li> <a [routerLink]="['/about']" routerLinkActive="router-link-active">about</a> </li>
          <li> <a [routerLink]="['/privacy']" routerLinkActive="router-link-active" >privacy</a> </li>
        </ul>
      </div>
    </div>
  </section>
</footer>
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CrossCuttingModule } from './modules/cross-cutting/cross-cutting.module';
import { SiteModule } from './modules/site/site.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { CoreModule } from './modules/core/core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, SharedModule, CrossCuttingModule.forRoot(), AppRoutingModule, SiteModule],
  exports: [CoreModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

<mat-toolbar color="primary" class="mat-toolbar">
  <mat-toolbar-row>
    <a [routerLink]="['']" routerLinkActive="router-link-active"><img class="aa-navigation-toolbar--logo"
      src="assets/images/logo.png" /></a>
    <span class="menu-spacer"></span>
    <div class="aa-navigation--menu">
      <div class="aa-navigation--menu-item">
        <a [routerLink]="['/book/angular-architecture-patterns']" routerLinkActive="router-link-active"
          title="Angular Architecture Patterns" >book</a>
      </div>  
      <div class="aa-navigation--menu-item">
        <a [routerLink]="['/book/book-offer']" routerLinkActive="router-link-active"
          title="Angular Architecture Patterns" >Free Guide</a>
      </div>  
      <div class="aa-navigation--menu-item">
        <a [routerLink]="['/podcast']" routerLinkActive="router-link-active"
          title="Angular Architecture Podcast" >podcast</a>
      </div>
      <div class="aa-navigation--menu-item">
        <a [routerLink]="['/blog']" routerLinkActive="router-link-active"
          title="Angularlicious Blog" >blog</a>
      </div> <div class="aa-navigation--menu-item">
        <a [routerLink]="['/about']" routerLinkActive="router-link-active"
          title="About Angular Architecture" >about</a>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialImportsModule } from '../material-imports/material-imports.module';

const MODULES = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  HttpClientModule,
  MaterialImportsModule, //CONVENIENCE COMES WITH A PRICE;
  ReactiveFormsModule,
  RouterModule,
];
@NgModule({
  declarations: [],
  imports: [...MODULES],
  exports: [...MODULES],
})
export class SharedModule {}

import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@angular-architecture/configuration';

@Component({
  selector: 'aa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  copyrightYear = 2020;
  copyright: string;
  social: {
    instagram?: { name: string; URL: string };
    twitter?: { name: string; URL: string };
    facebook?: { name: string; URL: string };
    github?: { name: string; URL: string };
    gitlab?: { name: string; URL: string };
    linkedin?: { name: string; URL: string };
  };

  constructor(private config: ConfigurationService) {}

  ngOnInit(): void {
    this.setPrivacyEffectiveDate();

    this.social = this.config.settings.webConfig.social;
  }

  private setPrivacyEffectiveDate() {
    const currentYear = new Date().getFullYear();
    if (currentYear > this.copyrightYear) {
      this.copyright = `${this.copyright}-${currentYear}`;
    } else {
      this.copyright = this.copyrightYear.toString();
    }
  }
}

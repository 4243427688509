import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '@angular-architecture/foundation';
import { LoggingService } from '@angular-architecture/logging';
import { Router } from '@angular/router';

@Component({
  selector: 'aa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent extends ComponentBase implements OnInit {
  constructor(loggingService: LoggingService, router: Router) {
    super('HeaderComponent', loggingService, router);
  }

  ngOnInit(): void {}
}

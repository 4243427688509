import { IConfiguration } from '@angular-architecture/configuration';

export const AppConfig: IConfiguration = {
  contentful: {
    spaceId: '8d82gs442h2g',
    token: '8538d5457bcb57d9f1209f201ebf0e74025dd1f7618fa881449dcdf81a2c2002',
  },
  loggingConfig: {
    applicationName: 'angular-architecture',
    isProduction: false,
  },
  errorHandlingConfig: {
    applicationName: 'angular-architecture',
    includeDefaultErrorHandling: true,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCBh0mZ95iKKx2Jt_f4bVEChUWWXw26ulw',
    authDomain: 'angularlicious.firebaseapp.com',
    databaseURL: 'https://angularlicious.firebaseio.com',
    projectId: 'angularlicious',
    storageBucket: 'angularlicious.appspot.com',
    messagingSenderId: '296251746847',
    appId: '1:296251746847:web:58ba1c631202358178432a',
  },
  logglyConfig: {
    applicationName: 'angular-architecture',
    apiKey: '01e4b3aa-f301-43e7-bf60-40ba5d0729d4',
    sendConsoleErrors: true,
  },
  webConfig: {
    applicationName: 'angular-architecture',
    version: '1.0.0',
    social: {
      github: { name: 'Angularlicious', URL: 'https://github.com/angularlicious' },
      gitlab: { name: 'Angular-Architecture', URL: 'https://gitlab.com/angular-architecture' },
      instagram: { name: 'AngularArchitecture', URL: 'https://www.instagram.com/AngularArchitecture/' },
      twitter: { name: '@AngularArch', URL: 'https://twitter.com/AngularArch' },
      linkedin: { name: `Matt Vaughn's LI profile`, URL: 'https://www.linkedin.com/in/matt-vaughn' },
    },
    companyEffectiveDate: new Date(2020, 4, 3),
    email: 'matt@angulararchitecture.com',
    website: 'Angular Architecture',
    blogURL: 'https://www.medium.com/@angularlicious',
    motto: 'We provide you with simple and straight-forward guidance for building Angular applications with best practices, principles, tools, processes and of course architecture.',
    bookPrice: '$14.99',
  },
};
